//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    height: {
      type: String,
      default: "150px",
    },
    topPadded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Nothing in here!",
    },
    desc: {
      type: String,
      default: "no content was found",
    },
    image: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "mdi-emoticon-sad",
    },
  },
};
