import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=629493a4&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=629493a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629493a4",
  null
  
)

export default component.exports
import {QHeader,QIcon,QToolbar,QToolbarTitle,QBtn,QBadge,QMenu,QList,QItem,QItemSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QHeader,QIcon,QToolbar,QToolbarTitle,QBtn,QBadge,QMenu,QList,QItem,QItemSection})
