//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { profile_service } from "@/services";
import _ from "lodash";
export default {
  props: {
    height: {
      type: Number,
      default: 135,
    },
    width: {
      type: Number,
      default: 180,
    },
  },
  data() {
    return {
      count: undefined,
      fetch_size: 10,
      loading: false,
      celebrities: [],
      thumbStyle: {
        right: "2px",
        borderRadius: "1px",
        backgroundColor: "#f7cd23",
        opacity: 0.75,
        height: "2px",
      },
    };
  },
  computed: {
    on_scroll_decounced() {
      return _.debounce(this.on_scroll, 300);
    },
  },
  mounted() {
    this.fetch_celebrities();
  },
  methods: {
    on_scroll(info) {
      if (info.horizontalPercent < 0.8) return;
      this.fetch_celebrities();
    },
    fetch_celebrities() {
      if (this.loading) return;
      if (this.celebrities.length >= this.count) return;
      this.loading = true;
      profile_service
        .get({
          is_celeb: true,
          limit: this.fetch_size,
          offset: this.celebrities.length,
        })
        .then((data) => {
          this.celebrities.push(...data.results);
          this.loading = false;
          this.count = data.count;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
