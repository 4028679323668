//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import settings from "@/settings";
import { AUTH_LOGOUT } from "@/store/actions";
export default {
  data() {
    return {
      action_btns: settings.data.action_btns,
    };
  },
  methods: {
    auth_action() {
      if (this.is_authenticated) {
        this.$store.dispatch(AUTH_LOGOUT);
      } else {
        if (this.$route.name !== "login") this.$router.push({ name: "login" });
      }
    },
  },
  computed: {
    auth_action_str() {
      if (this.is_authenticated) return "Sign Out";
      else return "Sign In";
    },
    filtered_actions() {
      return this.action_btns.filter(
        (item) => !item.auth || this.is_authenticated
      );
    },
    show_wip_bar() {
      return process.env.VUE_APP_WIP === "true";
    },
  },
};
