import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=c3fc69de&scoped=true&"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=c3fc69de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3fc69de",
  null
  
)

export default component.exports
import {QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon})
