//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
    showState: {
      type: Boolean,
      default: false,
    },
    menuBtn: {
      type: Boolean,
      default: false,
    },
    runtime: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    runtime_txt() {
      // runtime cannot exeed 60 minutes
      var minutes = this.runtime;
      if (minutes) {
        if (minutes < 10) minutes = "0" + minutes;
        return `${minutes}:00`;
      }
      return "";
    },
    state_color() {
      return {
        C: "red-4",
        S: "primary",
        P: "green-4",
        R: "red-4",
      }[this.state];
    },
    state_txt() {
      return {
        C: "Incomplete",
        S: "Pending",
        P: "Approved",
        R: "Rejected",
      }[this.state];
    },
  },
};
