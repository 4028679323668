//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HorizontalItems from "@/components/HorizontalItems";
import Movie from "@/components/movie/Movie";
import MovieSkeleton from "@/components/movie/Skeleton";
import _ from "lodash";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    movies: {
      type: Array,
      default() {
        return [];
      },
    },
    width: {
      type: Number,
      default: 230,
    },
    height: {
      type: Number,
      default: 110,
    },
  },
  components: { Movie, HorizontalItems, MovieSkeleton },
  data() {
    return {
      //   movies: [],
      thumbStyle: {
        right: "2px",
        borderRadius: "1px",
        backgroundColor: "#f7cd23",
        opacity: 0.75,
        height: "2px",
      },
    };
  },
  computed: {
    on_scroll_decounced() {
      return _.debounce(this.on_scroll, 300);
    },
  },
  methods: {
    on_scroll(info) {
      if (info.horizontalPercent < 0.8) return;
      this.$emit("onLoadMore");
    },
  },
};
