//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import settings from "@/settings";
import BaseLayout from "@/layouts/Base";
import CelebrityCurators from "@/components/home/CelebrityCurators";
import NewReleases from "@/components/home/NewReleases";
import ContestReleases from "@/components/home/ContestReleases";
import MpGenreMovies from "@/components/home/MpGenreMovies";
import MostRecommended from "@/components/home/MostRecommended";

import SearchAndOrFiltered from "@/components/home/search/SearchAndOrFiltered";
import { contest_service, mp_genre_service } from "@/services";
import { mapState } from "vuex";
import { LANG_REQUEST, GENRE_REQUEST } from "@/store/actions";
export default {
  name: "home-page",
  components: {
    BaseLayout,
    NewReleases,
    ContestReleases,
    CelebrityCurators,
    MpGenreMovies,
    MostRecommended,
    SearchAndOrFiltered,
  },
  metaInfo: {
    title: "Home",
  },
  data() {
    return {
      search_text: "",
      selected_genres: [],
      selected_langs: [],
      selected_time: [],
      show_filter: false,
      filter_action_btn: {
        icon: "mdi-filter-outline",
        active_icon: "mdi-filter",
        count: 0,
        to: this.show_filters,
        type: "dialog",
        auth: false,
      },
      live_contests: [],
      live_mp_genres: [],
    };
  },
  created() {
    this.$store.dispatch(LANG_REQUEST);
    this.$store.dispatch(GENRE_REQUEST);
  },
  mounted() {
    settings.addActionBtn(this.filter_action_btn);
    this.fetch_live_contests();
    this.fetch_live_mp_genres();
  },
  beforeDestroy() {
    settings.removeActionBtn(this.filter_action_btn);
  },
  computed: {
    ...mapState("genre", {
      genres: (state) => state.genres,
    }),
    ...mapState("lang", {
      langs: (state) => state.langs,
    }),
    filter_langs() {
      var langs = [];
      this.langs.forEach((lang) => {
        langs.push({ label: lang.name, val: lang.name });
      });
      return langs;
    },
    filter_genres() {
      var genres = [];
      this.genres.forEach((genre) => {
        genres.push({ label: genre.name, val: genre.name });
      });
      return genres;
    },
    filters() {
      return {
        lang: this.filter_langs,
        time: [
          { label: "Live", val: "live" },
          { label: "Last Week", val: "week" },
          { label: "Last Month", val: "month" },
        ],
        genre: this.filter_genres,
      };
    },
  },
  watch: {
    selected_genres() {
      this.filter_action_btn.count = this.get_filter_type_count();
    },
    selected_time() {
      this.filter_action_btn.count = this.get_filter_type_count();
    },
    selected_langs() {
      this.filter_action_btn.count = this.get_filter_type_count();
    },
  },
  methods: {
    get_filter_type_count() {
      var res = 0;
      if (this.selected_genres.length > 0) res += 1;
      if (this.selected_time.length > 0) res += 1;
      if (this.selected_langs.length > 0) res += 1;
      return res;
    },
    fetch_live_contests() {
      contest_service
        .get({ visible_in_home_page: true })
        .then((data) => {
          this.live_contests.push(...data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetch_live_mp_genres() {
      mp_genre_service
        .get()
        .then((data) => {
          this.live_mp_genres.push(...data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    detail_page(movie) {
      var movie_id = movie.id;
      movie_id = 1;
      this.$router.push({
        name: "movie-detail",
        params: { id: movie_id, slug: this.slugify(movie.title) },
      });
    },
    show_filters() {
      this.show_filter = !this.show_filter;
    },
    clear_filters() {
      this.selected_genres.splice(0, this.selected_genres.length);
      this.selected_langs.splice(0, this.selected_langs.length);
      this.selected_time.splice(0, this.selected_time.length);
      this.search_text = "";
    },
  },
};
