//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from "lodash";
export default {
  props: {
    height: {
      type: Number,
      default: 110,
    },
  },

  data() {
    return {
      thumbStyle: {
        right: "2px",
        borderRadius: "1px",
        backgroundColor: "#f7cd23",
        opacity: 0.75,
        height: "2px",
      },
    };
  },
  computed: {
    on_scroll_decounced() {
      return _.debounce(this.on_scroll, 300);
    },
  },
  methods: {
    on_scroll(info) {
      if (info.horizontalPercent < 0.8) return;
      this.$emit("onLoadMore");
    },
  },
};
