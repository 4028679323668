//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    height: {
      type: Number,
      default: 195,
    },
    width: {
      type: Number,
      default: 110,
    },
  },
};
