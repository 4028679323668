//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MovieImage from "@/components/movie/Image";
export default {
  components: {
    MovieImage,
  },
  props: {
    showState: {
      type: Boolean,
      default: false,
    },
    cardStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    emitSelection: {
      type: Boolean,
      default: false,
    },
    showMyRoles: {
      type: Boolean,
      default: true,
    },
    menuBtn: {
      type: Boolean,
      default: false,
    },
    movie: {
      type: Object,
      default() {
        return {};
      },
    },
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    my_roles_txt() {
      var txt = "";
      this.my_roles.forEach((role) => {
        txt += role.role + ", ";
      });
      return txt.slice(0, -2);
    },
    my_roles() {
      return this.movie.crew.filter(
        (item) => item.profile_id == this.profile.id
      );
    },
  },
  methods: {
    on_movie_select() {
      if (this.emitSelection) this.$emit("select");
      else
        this.$router.push({
          name: "movie-detail",
          params: {
            id: this.movie.id,
            slug: this.slugify(this.movie.title),
          },
        });
    },
  },
};
